import React, { useContext } from "react";
import PropTypes from "prop-types";
import { FaAngleDown, FaCheck } from "react-icons/fa";
import useTranslations from 'hooks/useTranslations';

import Video from "components/mdx-components/video";
import { ThemeContext } from "context/theme-context";
import { rhythm, scale } from "utils/typography";
import { mq, truncate, localizedDate } from "utils/helper";
import TruncatedText from 'components/TruncatedText';

function UnitDetails({ unit, locale }) {
  const { colors } = useContext(ThemeContext);
  const tr = useTranslations();
  const isRTL = locale === "ar";
  const {
    incident_date: date,
    filename,
    incident_code: code,
    is_graphic: isGraphic,
    location_ar,
    location_en,
    online_link: onlineLink,
    summary_ar,
    summary_en,
  } = unit;

  const link = `https://cube.syrianarchive.org${filename.slice(14)}`;
  return (
    <div
      css={{
        display: "grid",
        gridTemplateColumns: "2fr 1fr",
        gridColumnGap: rhythm(3),
        backgroundColor: colors.light,
        border: `3px solid ${colors.dark}`,
        padding: `${rhythm(3)} ${rhythm(5)}`,
        direction: isRTL ? "rtl" : "ltr",
        [mq[0]]: {
          display: "block",
          padding: rhythm(1)
        }
      }}
    >
      <div>
        <h2
          css={{
            marginTop: 0,
            [mq[0]]: {
              ...scale(0.6)
            }
          }}
        >
          <TruncatedText lines={2}>
            {isRTL ? summary_ar : summary_en}
          </TruncatedText>
        </h2>
        <small css={{ display: "block", opacity: 0.6 }}>
          <FaCheck />
          {tr("verified observation")}: {code}
        </small>
        <small
          css={{
            backgroundColor: "#C20A0A",
            color: colors.light,
            fontWeight: "bold",
            padding: rhythm(0.2),
            paddingBottom: 0
          }}
        >
          {tr("warning: this video may contain graphic content")}
        </small>
        <div css={{ marginTop: rhythm(1), marginBottom: rhythm(1) }}>
          <Video src={link} isGraphic={isGraphic} />
        </div>
        <a href={link}>
          {tr("Download file")} <FaAngleDown />
        </a>
        <dl
          css={{
            marginTop: rhythm(2)
          }}
        >
          <dt>{tr("online link")}</dt>
          <dd>
            <a href={onlineLink}>{tr("link")}</a>
          </dd>
        </dl>
      </div>

      <div>
        <dl>
          <dt>{tr("Incident")}:</dt>
          <dd>{code}</dd>
          <dt>{tr("summary")}</dt>
          <dd
            css={{
              lineBreak: "strict",
              maxHeight: "300px",
              maxWidth: "300px",
              overflowY: "auto"
            }}
          >
            {isRTL ? summary_ar : summary_en}
          </dd>
          <dt>{tr("date of occurency")}</dt>
          <dd>{localizedDate(date, "y,M,d")}</dd>
          <dt>{tr("location")} </dt>
          <dd>{isRTL ? location_ar : location_en}</dd>
          <dt>{tr("collections")}:</dt>
          <dd>{tr("chemical dataset")}</dd>
        </dl>
      </div>
    </div>
  );
}

UnitDetails.propTypes = {
  unit: PropTypes.object,
  locale: PropTypes.string
};

export default UnitDetails;
